import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { PlatformAdminModule } from '@engineering11/platform-admin-web'
import { LayoutOneComponent } from './components/layouts/layout-one/layout-one.component'

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'sdk',
    component: LayoutOneComponent,
    loadChildren: () => {
      return PlatformAdminModule
    },
  },
  {
    path: 'testing',
    loadChildren: () => import('./modules/testing/testing.module').then(m => m.TestingModule),
  },
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 120],
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      enableTracing: false,
      urlUpdateStrategy: 'eager',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
