import { Injectable } from '@angular/core'
import { IConversationTypeConfig, BaseConversationConfigProvider } from '@engineering11/messaging-web'
import { ConversationTypes, DEFAULT_CONVERSATION_CONFIG } from '../../constants'

@Injectable({ providedIn: 'root' })
export class CandidatePoolDmConfigProvider extends BaseConversationConfigProvider {
  conversationType = ConversationTypes.CandidatePoolDM
  private config: IConversationTypeConfig = {
    ...DEFAULT_CONVERSATION_CONFIG,
    maxMinutesToEditOwnMessages: 15,
    numberOfEditOwnMessages: 5,
  }

  getConfig(): IConversationTypeConfig {
    return this.config
  }

  setConfig(config?: Partial<IConversationTypeConfig>): void {
    this.config = { ...this.config, ...config }
  }
}
