import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { E11ComponentLibraryModule } from '../_component-library/component-library.module'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { SharedLibModule } from 'shared-lib'
import { environment } from '@admin/environments/environment'
import { ERROR_HANDLING } from '@admin/app/app.constants'
import { AppHeaderComponent } from '@admin/app/components/header/header.component'
import { AppHeaderUnAuthComponent } from '@admin/app/components/header/header-unauth.component'
import { AppFooterComponent } from '@admin/app/components/footer/footer.component'
import { AppNavHeaderComponent } from '@admin/app/components/nav-header/nav-header.component'
import { AppLayoutUnauthenticatedComponent } from '@admin/app/components/layouts/layout-unauthenticated/layout-unauthenticated.component'
import { AppLayoutAdminComponent } from '@admin/app/components/layouts/layout-admin/layout-admin.component'
import { FileNotFoundComponent } from '@admin/app/components/_file-not-found/file-not-found.component'
import { LayoutOneComponent } from '@admin/app/components/layouts/layout-one/layout-one.component'
import { ProfileDropdownComponent } from '@admin/app/components/profile-dropdown/profile-dropdown.component'
import { MobileDrawerIconComponent } from '@admin/app/components/mobile-drawer-icon/mobile-drawer-icon.component'

const DeclarationsAndExports = [
  AppHeaderComponent,
  AppHeaderUnAuthComponent,
  ProfileDropdownComponent,
  AppFooterComponent,
  AppNavHeaderComponent,
  FileNotFoundComponent,
  LayoutOneComponent,
  AppLayoutUnauthenticatedComponent,
  AppLayoutAdminComponent,
  MobileDrawerIconComponent,
]

@NgModule({
  declarations: [...DeclarationsAndExports],
  imports: [CommonModule, E11ComponentLibraryModule, RouterModule, TranslateModule, SharedLibModule.forRoot(environment, ERROR_HANDLING)],
  exports: [TranslateModule, E11ComponentLibraryModule, ...DeclarationsAndExports],
})
export class SharedModule {}
